import axios from "axios";
const dyrectApi = process.env.REACT_APP_DYRECT_API_URL;

export const getScheduledPodcasts = async () => {
    try {
        const path = "podcasts/scheduled";
        const response = await axios.get(dyrectApi + path + `?UserID=${localStorage.getItem('user_id')}`);
        return response;
    } catch (error) {
        return 'Unable to fetch scheduled podcasts';
    }
};

export const createPodcast = async (data) => {
    try {
        const path = "podcasts/scheduled";
        const response = await axios.post(dyrectApi + path , data);
        return response;
    } catch (error) {
        return 'Unable to create podcast';
    }
};

export const updatePodcast = async (data) => {
    try {
        const path = "podcasts/scheduled";
        const response = await axios.put(dyrectApi + path , data);
        return response;
    } catch (error) {
        return 'Unable to update scheduled podcast';
    }
};

export const deletePodcast = async (data) => {
    try {
        const path = "podcasts/scheduled";
        const response = await axios.delete(dyrectApi + path + `?ScheduleID=${data}`);
        return response;
    } catch (error) {
        return 'Unable to delete scheduled podcast';
    }
};
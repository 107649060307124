import axios from "axios";
const dyrectApi = process.env.REACT_APP_DYRECT_API_URL;

export const getShows = async () => {
    try {
        const path = "shows/list";
        const response = await axios.get(dyrectApi + path + `?UserID=${localStorage.getItem('user_id')}`);
        return response;
    } catch (error) {
        return 'Unable to fetch scheduled podcasts';
    }
};

export const getShowDetails = async (ShowID) => {
    try {
        const path = "shows/details";
        const response = await axios.get(dyrectApi + path + `?UserID=${localStorage.getItem('user_id')}&ShowID=${ShowID}`);
        return response;
    } catch (error) {
        return 'Unable to fetch scheduled podcasts';
    }
};

export const createShow = async (data) => {
    try {
        const path = "show";
        const response = await axios.post(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to fetch scheduled podcasts';
    }
};

export const updateShow = async (data) => {
    try {
        const path = "show";
        const response = await axios.put(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Unable to fetch scheduled podcasts';
    }
};
export const updateUserProfile = async (data) => {
    try {
        const path = "users";
        const response = await axios.put(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Error occurred during user profile updation';
    }
};
export const handleUserSignup = async (data) => {
    try {
        console.log("Signup data:",data)
        const path = "users";
        const response = await axios.post(dyrectApi + path, data);
        return response;
    } catch (error) {
        return 'Error occurred during signup';
    }
};
export const getUserProfileDetails = async () => {
    try {
        const path = "users";
        const response = await axios.get(dyrectApi + path + `?UserID=${localStorage.getItem('user_id')}`);
        return response;
    } catch (error) {
        return 'Error occurred while checking User profile';
    }
};
export const emailValidation = async (data) => {
    try {
        const path = "check/email";
        const response = await axios.get(dyrectApi + path + `?Email=${data}`);
        return response;
    } catch (error) {
        return 'Error occurred while checking User profile';
    }
};
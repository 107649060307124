import React from "react";
import { Dialog, DialogContent } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close"; // Close icon for the cross
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline"; // Error icon

function ErrorModal({ open, message, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          backgroundColor: "#e3342f", // More pleasant red
          color: "white",
          zIndex: 50,
        },
      }}
      style={{
        position: "fixed",
        inset: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 5000,
      }}
    >
      <div
        style={{
          borderRadius: "10px",
          overflow: "hidden",
          width: "450px", // Slightly wider
          padding: "8px", // Additional padding for spacing
        }}
      >
        <DialogContent
          style={{
            padding: "20px", // Slightly increased padding
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <ErrorOutlineIcon
            fontSize="large"
            style={{
              marginRight: "12px", // Increased margin
              color: "white",
            }}
          />
          <p
            style={{
              color: "white",
              fontSize: "16px", // Slightly larger font size
              margin: 0,
              flex: 1,
              padding: "0 5px"
            }}
          >
            {message}
          </p>
          <CloseIcon
            onClick={onClose}
            style={{
              cursor: "pointer",
              color: "white",
            }}
          />
        </DialogContent>
      </div>
    </Dialog>
  );
}

export default ErrorModal;
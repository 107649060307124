import React from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

const Loader = ({ message }) => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    //   minHeight="100vh"
    >
      <CircularProgress />
      <Typography variant="h6" sx={{ marginTop: 2, color: 'white' }}>
        {message}
      </Typography>
    </Box>
  );
};

export default Loader;

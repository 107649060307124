import { useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import SideBar from '../Components/Sidebar';
import { Button, Menu, MenuItem, Chip, Tooltip, TextField } from '@mui/material';
import { getShows } from '../Services/ShowsApi';
import Loader from '../Components/Loader';
import ProfileUpdate from './ProfileUpdate';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import SortIcon from '@mui/icons-material/Sort';  // Import the SortIcon
import { getUserProfileDetails } from "../Services/ShowsApi";

const Shows = () => {
    const [shows, setShows] = useState([]);
    const [loading, setLoading] = useState(true);
    const [imageLoaded, setImageLoaded] = useState({});
    const navigate = useNavigate();

    const [searchQuery, setSearchQuery] = useState('');
    const [visibleCount, setVisibleCount] = useState(8);
    const [sortOrder, setSortOrder] = useState({ field: 'CreatedDttm', direction: 'desc' });

    // State to manage the menu open state
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);

    const [openModal, setOpenModal] = useState(false);

    const handleCloseModal = () => {
        setOpenModal(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
        setOpen(true);
    };

    const handleClose = () => {
        setAnchorEl(null);
        setOpen(false);
    };

    // Fetch shows from API
    useEffect(() => {
        const fetchShows = async () => {
            try {
                const response = await getShows();
                setShows(response?.data?.Data);
                const profileStatus = await getUserProfileDetails();
                if (profileStatus?.data?.body?.Data?.length > 0 && profileStatus.data.body.Data[0]?.isNameEmpty === 1) {
                    setOpenModal(true); 
                }
                else{
                    setOpenModal(false);
                }
            } catch (error) {
                console.error("Error fetching shows:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchShows();
    }, []);

    // Handle sorting change (by date or name)
    const handleSortChange = (field, direction) => {
        setSortOrder({ field, direction });
        handleClose(); // Close the menu after selection
    };

    // Handle the search input change
    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    // Filter and sort shows based on search query and sort order
    const filteredShows = shows && shows
        ? shows
            .filter((show) => show.ShowName.toLowerCase().includes(searchQuery.toLowerCase()))
            .sort((a, b) => {
                if (sortOrder.field === 'CreatedDttm') {
                    const dateA = new Date(a.CreatedDttm);
                    const dateB = new Date(b.CreatedDttm);
                    return sortOrder.direction === 'asc' ? dateA - dateB : dateB - dateA;
                } else if (sortOrder.field === 'ShowName') {
                    const nameA = a.ShowName.toLowerCase();
                    const nameB = b.ShowName.toLowerCase();
                    return sortOrder.direction === 'asc' ? nameA.localeCompare(nameB) : nameB.localeCompare(nameA);
                }
                return 0;
            })
        : [];

    return (
        <div className="theme-bg clr-white height-100" style={{ display: "flex" }}>
            <SideBar />
            <div className="container-fluid overflow-x-none">
                <div className="row px-5">
                    <div className="container-fluid theme-bg-light my-5" style={{ minHeight: "80vh", color: "#fff" }}>
                        <div className="row">
                            <div className="col-6 my-4">
                                <h2>Shows</h2>
                            </div>
                            <div className="col-6 my-4 d-flex justify-content-end">
                                {/* Search Bar */}
                                <TextField
                                    label="Search Shows"
                                    variant="filled"
                                    size="small"
                                    value={searchQuery}
                                    onChange={handleSearchChange}
                                    style={{ marginRight: "20px", width: "40%" }}
                                    InputProps={{ style: { color: 'white' } }}
                                    InputLabelProps={{ style: { color: 'white' } }}
                                    sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}
                                />


                                <Button
                                    id="sort-button"
                                    aria-controls={open ? 'sort-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? 'true' : undefined}
                                    onClick={handleClick}
                                    variant="outlined"
                                    sx={{
                                        bgcolor: 'rgba(255, 255, 255, 0.1)',
                                        border: 'none'
                                    }}
                                >
                                    <SortIcon
                                        style={{ color: "white" }}
                                    />
                                </Button>

                                <Menu
                                    id="sort-menu"
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                    MenuListProps={{
                                        'aria-labelledby': 'sort-button',
                                    }}
                                >
                                    <MenuItem onClick={() => handleSortChange('CreatedDttm', 'desc')}>
                                        <ArrowDownwardIcon style={{ marginRight: '10px' }} /> Date (Descending)
                                    </MenuItem>
                                    <MenuItem onClick={() => handleSortChange('CreatedDttm', 'asc')}>
                                        <ArrowUpwardIcon style={{ marginRight: '10px' }} /> Date (Ascending)
                                    </MenuItem>
                                    <MenuItem onClick={() => handleSortChange('ShowName', 'desc')}>
                                        <ArrowDownwardIcon style={{ marginRight: '10px' }} /> Name (Descending)
                                    </MenuItem>
                                    <MenuItem onClick={() => handleSortChange('ShowName', 'asc')}>
                                        <ArrowUpwardIcon style={{ marginRight: '10px' }} /> Name (Ascending)
                                    </MenuItem>
                                </Menu>

                            </div>
                        </div>

                        {loading ? (
                            <Loader message="Fetching shows" />
                        ) : (
                            <div className="row" style={{ height: '74vh', overflowY: 'auto' }}>
                                {filteredShows.length === 0 && (
                                    <div className="col-12" style={{ textAlign: 'center' }}>
                                        <h4>No Shows Found</h4>
                                    </div>
                                )}
                                {filteredShows.slice(0, visibleCount).map((show, index) => (
                                    <div key={index} className="col-md-3 d-flex justify-content-center mb-4">
                                        <div
                                            className="position-relative pointer"
                                            style={{
                                                width: "100%",
                                                maxWidth: "300px",
                                                height: "fit-content",
                                            }}
                                            onClick={() => navigate(`/shows/details/${show.ShowID}`)}
                                        >
                                            {/* Display loader until image is loaded */}
                                            {!imageLoaded[index] && (
                                                <div className="image-loader-overlay">
                                                    {show.ThumbnailURL ? (
                                                        <Loader />
                                                    ) : (
                                                        <div className="image-loader-overlay">
                                                            <span>No Thumbnail present</span>
                                                        </div>
                                                    )}
                                                </div>
                                            )}
                                            <img
                                                src={show.ThumbnailURL}
                                                alt={show.ShowName}
                                                style={{
                                                    width: "100%",
                                                    height: "175px",
                                                    borderRadius: "8px",
                                                    objectFit: 'cover',
                                                    display: imageLoaded[index] ? 'block' : 'none',
                                                }}
                                                onLoad={() => setImageLoaded((prev) => ({
                                                    ...prev,
                                                    [index]: true,
                                                }))}
                                            />
                                            <Chip
                                                style={{
                                                    position: "absolute",
                                                    bottom: "20%",
                                                    height: 'auto',
                                                    right: "10px",
                                                    background: "#633B48",
                                                    color: "#fff",
                                                    borderRadius: "20px",
                                                    fontSize: "0.7em",
                                                }}
                                                label={show.RunTime}
                                            />
                                            <div
                                                style={{
                                                    width: "100%",
                                                    color: "#fff",
                                                    padding: "6px 12px",
                                                    display: "flex",
                                                    justifyContent: "space-between",
                                                    alignItems: "center",
                                                    borderRadius: "0 0 8px 8px",
                                                }}
                                            >
                                                <Tooltip title={show.ShowName} arrow placement="top">
                                                    <span style={{ fontWeight: "bold" }} className="ellipsis">
                                                        {show.ShowName}
                                                    </span>
                                                </Tooltip>
                                                <span style={{ fontSize: "0.9em" }}>
                                                    {new Date(show.CreatedDttm).toLocaleDateString()}
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {visibleCount < filteredShows.length && (
                                    <div className="d-flex justify-content-center">
                                        <div className="col-12" style={{ textAlign: 'center' }}>
                                            <Button
                                                variant="text"
                                                style={{
                                                    backgroundColor: 'white',
                                                    color: 'black',
                                                    borderRadius: '20px',
                                                    width: '20%',
                                                }}
                                                onClick={() => setVisibleCount(visibleCount + 8)}
                                            >
                                                <span className="text-trans-none">Load More</span>
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}

                        <ProfileUpdate open={openModal} onClose={handleCloseModal} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Shows;

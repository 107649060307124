import React, { useEffect, useState } from 'react';
import SideBar from '../Components/Sidebar';
import { Breadcrumbs, Button, Link } from '@mui/material';
import { getShowDetails } from '../Services/ShowsApi';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useParams } from 'react-router-dom';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import Loader from '../Components/Loader';

const ShowDetails = () => {

    const [showDetails, setShowDetails] = useState([]);
    const [loading, setLoading] = useState(true);
    const { ShowID } = useParams();
    const [isPlaying, setIsPlaying] = useState(false);

    const handlePlayClick = () => {
        setIsPlaying(true); // Set the state to show the video player
    };

    const breadcrumbs = [
        <Link underline="hover" key="1" color="white" href='/shows'>
            <h3>Shows</h3>
        </Link>,
        <Link
            underline="hover"
            key="2"
            color="white"
        >
            <h3>{showDetails?.ShowName}</h3>
        </Link>,
    ];

    useEffect(() => {
        const fetchShowDetails = async () => {
            try {
                const response = await getShowDetails(ShowID);
                setShowDetails(response?.data?.Data);
                console.log(response?.data?.Data);
            } catch (error) {
                console.error("Error fetching shows:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchShowDetails();
    }, [ShowID]);

    function formatDate(dateString) {
        const options = {
            year: 'numeric',
            month: 'short', // Short month format (e.g., 'Oct')
            day: 'numeric',
            hour: '2-digit', // 2-digit hour
            minute: '2-digit', // 2-digit minute
            hour12: true // 12-hour format with AM/PM
        };

        const date = new Date(dateString);
        return date.toLocaleString('en-US', options); // Format to US locale
    }

    return (
        <div className="theme-bg clr-white height-100" style={{ display: "flex" }}>
            <SideBar />
            <div className="container-fluid overflow-x-none">
                <div className="row px-5">
                    <div
                        className="container-fluid theme-bg-light my-5"
                        style={{ minHeight: "87vh", color: "#fff" }}
                    >
                        {loading ? (
                            <Loader message="Fetching your Show Details" />
                        ) : (
                            <div className="row">
                                <div className="col-12 my-4">
                                    <Breadcrumbs
                                        separator={<NavigateNextIcon fontSize="large" style={{ color: 'white' }} />}
                                        aria-label="breadcrumb"
                                    >
                                        {breadcrumbs}
                                    </Breadcrumbs>
                                </div>
                                <div className="col-6">
                                    {/* Container for image/video */}
                                    <div style={{ position: "relative", width: "100%", height: "350px" }}>
                                        {/* If video is not playing, show the image */}
                                        {!isPlaying ? (
                                            <>
                                                {showDetails?.Thumbnail ? (
                                                    <img
                                                        src={showDetails.Thumbnail}
                                                        alt="Show Details"
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            borderRadius: "8px",
                                                            objectFit: "cover",
                                                        }}
                                                    />
                                                ) : (
                                                    <div
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            backgroundColor: "#d3d3d3", // gray background
                                                            display: "flex",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                            borderRadius: "8px",
                                                            color: "#555", // text color
                                                            fontSize: "16px",
                                                            fontWeight: "bold",
                                                            textAlign: "center",
                                                        }}
                                                    >
                                                        Thumbnail not available
                                                    </div>
                                                )}

                                                {/* Overlay message when video is processing */}
                                                {showDetails?.ShowURL === null && (
                                                    <div
                                                        style={{
                                                            position: "absolute",
                                                            bottom: "0%",
                                                            left: "50%",
                                                            transform: "translate(-50%, -50%)",
                                                            backgroundColor: "rgba(0, 0, 0, 0.6)", // semi-transparent background
                                                            color: "#fff", // white text
                                                            padding: "10px 20px",
                                                            borderRadius: "5px",
                                                            textAlign: "center",
                                                            zIndex: 2, // Ensure it sits on top of the image
                                                        }}
                                                    >
                                                        Video is processing. Please come back in a while.
                                                    </div>
                                                )}

                                                {/* Play button */}
                                                {showDetails?.ShowURL && (
                                                    <div style={{ position: "absolute", bottom: "43%", left: "50%", transform: "translateX(-50%)" }}>
                                                        <button
                                                            onClick={handlePlayClick}
                                                            style={{
                                                                display: "inline-flex",
                                                                alignItems: "center",
                                                                padding: "10px 20px",
                                                                backgroundColor: "darkgray", // blue color for button
                                                                color: "#fff",
                                                                borderRadius: "5px",
                                                                border: "none",
                                                                cursor: "pointer",
                                                                zIndex: 1, // Ensure button is above overlay but below video
                                                            }}
                                                        >
                                                            <PlayArrowRoundedIcon style={{ fontSize: 'xx-large' }}></PlayArrowRoundedIcon>
                                                        </button>
                                                    </div>
                                                )}
                                            </>
                                        ) : (
                                            // If video is playing, replace the image with the video player
                                            <div style={{ width: "100%", height: "100%" }}>
                                                <video
                                                    controls
                                                    autoPlay
                                                    style={{
                                                        width: "100%",
                                                        height: "100%",
                                                        borderRadius: "8px",
                                                        objectFit: "cover",
                                                    }}
                                                >
                                                    <source src={showDetails.ShowURL} type="video/mp4" />
                                                    Your browser does not support the video tag.
                                                </video>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='col-6'>
                                    <h3>Transcripts</h3>
                                    <div style={{ height: '310px', overflowY: 'auto' }}>
                                        <span
                                            dangerouslySetInnerHTML={{
                                                __html: showDetails.Transcript
                                                    ? showDetails.Transcript.replace(/\n/g, '<br />')
                                                    : "Transcripts not available at the moment."
                                            }}
                                        />
                                    </div>
                                </div>



                                <div className='col-12'>
                                    <span>Recorded {formatDate(showDetails.CreatedAt)}</span>
                                </div>
                                <div className='col-12 mt-3'>
                                    <h3>Edited clips</h3>
                                    <div>
                                        <span>No clips available at the moment.</span>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShowDetails;

import React from 'react'

const MeetingLeft = () => {
    return (
        <div className='clr-white theme-bg' style={{
            alignContent: 'center',
            textAlign: 'center',
            height: '100vh',
            fontSize: '45px'
        }}>
            You left the meeting
            <br/>
            You can now close this window
        </div>
    )
}

export default MeetingLeft
// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Login from './Pages/Login';
import Signup from './Pages/Signup';
import Shows from './Pages/Shows';
import { createTheme, ThemeProvider } from '@mui/material';
import Schedules from "./Pages/Schedules";
import Schedule from "./Pages/Schedule";
// import JoinStudio from "./Pages/JoinStudio";
import Community from "./Pages/Community";
import Settings from "./Pages/Settings";
import BrandCentral from "./Pages/BrandCentral";
// import Studio from "./Pages/Studio";
import ShowDetails from "./Pages/ShowDetails";
import Meeting from "./Meeting/Meeting";
import JoinStudio from "./Pages/JoinStudio";
import Studio from "./Pages/Studio";
import Header from "./Components/Header";
import MeetingLeft from "./Pages/MeetingLeft";

const theme = createTheme({
  typography: {
    fontFamily: 'Nexa Extra Light',
  },
});

function App() {

  return (
    // <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
        <Header />
          <Routes>
          <Route path="/*" element={<Signup />} />
            <Route path="/login" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route path="/shows" element={<Shows />} />
            <Route path="/shows/details/:ShowID" element={<ShowDetails />} />
            <Route path="/schedules" element={<Schedules />} />
            <Route path="/schedules/create" element={<Schedule />} />
            {/* <Route path="/schedules/join-studio" element={<JoinStudio />} /> */}
            <Route path="/community" element={<Community />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/brand-central" element={<BrandCentral />} />
            {/* <Route path="/studio" element={<Studio />} /> */}
            <Route path="/meeting" element={<Meeting />} />
            <Route path="/left-meeting" element={<MeetingLeft />} />
          </Routes>
        </div>
      </Router>
  );
}

export default App;

import React, { useRef, useEffect } from 'react';

const WarningDialog = ({ open, onClose, onConfirm, title, description }) => {
    const dialogRef = useRef(null);

    useEffect(() => {
        const dialog = dialogRef.current;
        if (open) {
            dialog.showModal();
        } else if (dialog?.open) {
            dialog.close();
        }
    }, [open]);


    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Escape") {
                onClose();
            }
        };
        window.addEventListener("keydown", handleKeyDown);
        return () => window.removeEventListener("keydown", handleKeyDown);
    }, [onClose]);

    return (
        <dialog
            ref={dialogRef}
            className="dialog-container"
            onCancel={onClose}
        >
            <div className="dialog-content">
                <h2 className="dialog-title">{title}</h2>
                <p className="dialog-description">{description}</p>
                <div className="dialog-actions">
                    <button
                        onClick={onClose}
                        className="dialog-button dialog-cancel"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={onConfirm}
                        className="dialog-button dialog-confirm"
                    >
                        Delete
                    </button>
                </div>
            </div>
        </dialog>
    );
};

export default WarningDialog;
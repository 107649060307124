import React, { useEffect, useState } from "react";
import { TextField, Button, Checkbox, FormControlLabel, IconButton, InputAdornment } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { faGoogle, faFacebook, faApple } from "@fortawesome/free-brands-svg-icons";
import "./Pages.css";
import logo from "./../Assests/dyrect-logo.png";
import { useNavigate } from "react-router-dom";
import { AuthService, signIn } from "../Services/AuthService";
import ErrorModal from "../Components/ErrorModal";

function Login() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [popup, setPopup] = useState({ open: false, title: "", message: "" });
  const handlePopupClose = () => setPopup({ open: false, title: "", message: "" });
  const navigate = useNavigate();
  const cognitoDomain = process.env.REACT_APP_COGNITO_DOMAIN; // Replace with your Cognito domain
  const clientId = process.env.REACT_APP_CLIENT_ID;
  const redirectUri = "http://localhost:3000/login"; // Set this to your login page's callback URL
  const googleProvider = "Google";

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    // navigate("/shows");
    try {
      // }
      signIn(email, password)
        .then(result => {
          localStorage.setItem('user_id', result.getIdToken().payload['sub']);
          navigate("/shows");
        })
        .catch(err => {
          console.error('Sign-in error:', err);
          setPopup({
            open: true,
            message: "It seems the username or password you entered is incorrect. Please try again.",
          });
        });
    } catch (err) {
      console.error("Network or other error:", err);
      setPopup({
        open: true,
        message: "An error occurred during login. Please try again later.",
      });
    }
  };

  const handleGoogleLogin = () => {
    const googleLoginUrl = `https://${cognitoDomain}/oauth2/authorize?identity_provider=${googleProvider}&redirect_uri=${redirectUri}&response_type=code&client_id=${clientId}&scope=openid+profile+email`;
    window.location.href = googleLoginUrl;
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get("code");

    if (code) {
      localStorage.setItem("accessToken", code);
      navigate("/shows");
    }
  }, [navigate]);

  const signUp = () => {
    navigate("/signup");
  };

  return (
    <div className="height-100 clr-white">
      <div className="container-fluid">
        <div className="row theme-bg">
          <div className="col-xl-7 col-lg-6">
            <div className="logo-container">
              <img src={logo} alt="Dyrect Logo" className="logo-image" />
              <span className="logo-text">yrect</span>
            </div>
            <div className="welcome-grid height-100">Welcome Back!</div>
            <div className="ellipse" />
          </div>
          <div className="col-xl-5 col-lg-6" style={{ zIndex: "1000" }}>
            <div className="login-grid m-5">
              <h2 className="login-title">Login</h2>
              <p className="login-subtitle">Glad you're back.!</p>
              <TextField
                label="Username"
                variant="outlined"
                fullWidth
                margin="normal"
                className="input-label input-field"
                onChange={(e) => setEmail(e.target.value)}
              />

              <TextField
                label="Password"
                type={showPassword ? "text" : "password"}
                variant="outlined"
                fullWidth
                margin="normal"
                className="input-label input-field"
                onChange={(e) => setPassword(e.target.value)}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        edge="end"
                      >
                        {showPassword ? <VisibilityOff className="clr-white" /> : <Visibility className="clr-white" />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />

              <FormControlLabel
                control={
                  <Checkbox
                    name="remember"
                    color="primary"
                    sx={{
                      color: "#fff",
                      "&.Mui-checked": {
                        color: "#fff",
                      },
                    }}
                  />
                }
                label="Remember me"
                className="remember-me"
              />

              <Button variant="contained" color="primary" fullWidth className="login-button" onClick={handleLogin}>
                Login
              </Button>

              <p className="forgot-password">
                <a>Forgot password?</a>
              </p>

              <div className="or-section">
                <span>Or</span>
              </div>

              <div className="social-icons">
                <IconButton aria-label="Google" className="social-icon google" onClick={handleGoogleLogin}>
                  <FontAwesomeIcon icon={faGoogle} style={{ color: "#db4437", fontSize: "1.5em" }} />
                </IconButton>
                <IconButton aria-label="Facebook" className="social-icon facebook" onClick={() => alert("Facebook clicked")}>
                  <FontAwesomeIcon icon={faFacebook} style={{ color: "#4267B2", fontSize: "1.5em" }} />
                </IconButton>
                <IconButton aria-label="Apple" className="social-icon apple" onClick={() => alert("Apple clicked")}>
                  <FontAwesomeIcon icon={faApple} style={{ color: "#ffffff", fontSize: "1.5em" }} />
                </IconButton>
              </div>
              <p className="signup-text">
                Don't have an account? <span className="links" onClick={signUp}>Signup</span>
              </p>
              <div className="footer-links">
                <a href="javascript:void(0)">Terms & Conditions</a> <a href="javascript:void(0)">Support</a>{" "}
                <a href="javascript:void(0)">Customer Care</a>
              </div>
            </div>
          </div>
          <div className="bottom-ellipse"></div>
        </div>
        <ErrorModal
          open={popup.open}
          title={popup.title}
          message={popup.message}
          onClose={handlePopupClose}
        />
      </div>
    </div>
  );
}

export default Login;

import React, { useState } from 'react';
import { TextField, Chip, Button as MUIButton, IconButton, InputAdornment, Button, TextareaAutosize } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import './Pages.css'; // Additional custom styling, if needed
import SideBar from '../Components/Sidebar';
import { DateTimePicker, DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { createPodcast, updatePodcast } from '../Services/SchedulesApi';
import ErrorModal from '../Components/ErrorModal';

const Schedule = () => {

    const navigate = useNavigate();
    const location = useLocation(); // Hook to access location state
    const { podcast } = location.state || {};

    const parsedStartDate = podcast?.StartDttm ? dayjs(podcast.StartDttm) : null;
    const parsedEndDate = podcast?.EndDttm ? dayjs(podcast.EndDttm) : null;

    const [title, setTitle] = useState(podcast?.Title || "");
    const [startDate, setStartDate] = useState(parsedStartDate || dayjs()); // Set parsed value
    const [endDate, setEndDate] = useState(parsedEndDate || dayjs()); // Set parsed value
    const [guestEmails, setGuestEmails] = useState(podcast ? JSON.parse(podcast?.Guests) || [] : []);
    const [agenda, setAgenda] = useState(podcast?.Agenda || "");
    const [emailInput, setEmailInput] = useState("");
    const [guestInfo, setGuestInfo] = useState(podcast?.GuestInfo || []);
    const [importantLinks, setImportantLinks] = useState(podcast?.ImportantLinks || "");
    const [popup, setPopup] = useState({ open: false, title: "", message: "" });
    const handlePopupClose = () => setPopup({ open: false, title: "", message: "" });

    const handleAddGuestEmail = () => {
        if (emailInput.trim()) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple regex for email validation
            if (emailRegex.test(emailInput)) {
                setGuestEmails([...guestEmails, emailInput.trim()]);
                setEmailInput("");
            } else {
                setPopup({
                    open: true,
                    title: "Invalid Email",
                    message: "Please enter a valid email address.",
                });
            }
        }
    };

    const handleStartDateChange = (newDate) => {
        setStartDate(newDate); // Ensure this handles `null` or undefined properly
    };

    const handleEndDateChange = (newDate) => {
        setEndDate(newDate); // Ensure this handles `null` or undefined properly
    }

    const handleSave = async () => {
        if (!title.trim() || !startDate || !endDate) {
            setPopup({
                open: true,
                title: "Missing Information",
                message: "Please fill in the Title, Start Date, and End Date to continue. Thank you!",
            });
            return;
        }
        // Save the schedule data (for both create and edit)
        const data = {
            UserID: localStorage.getItem('user_id'),  // This can be dynamically set based on user authentication
            ScheduleID: podcast?.ScheduleID || `schedule-${Date.now()}`,  // Generate a new ID for create or use existing for edit
            Title: title,
            StartDttm: startDate?.format("YYYY-MM-DD HH:mm:ss"),
            EndDttm: endDate?.format("YYYY-MM-DD HH:mm:ss"),
            Guests: guestEmails,
            Agenda: agenda,
            GuestInfo: guestInfo, // You can replace with actual guest details if available
            ImportantLinks: importantLinks  // Optional: Set this to the appropriate value if needed
        };

        if (podcast) {
            // Update existing podcast
            console.log("Updating podcast with data:", data);
            // Add the update logic here (e.g., API call to update podcast)
            await updatePodcast(data);
            navigate("/schedules");
        } else {
            // Create new podcast
            console.log("Creating new podcast with data:", data);
            await createPodcast(data);
            navigate("/schedules");
        }
    };

    const handleDeleteGuestEmail = (emailToDelete) => {
        setGuestEmails(guestEmails.filter(email => email !== emailToDelete));
    };

    const handleJoinStudio = () => {
        navigate(`/meeting?meetingId=${podcast.MeetingID}&showName=${title}`);
    }

    return (
        <div className='theme-bg clr-white height-100' style={({ display: "flex" })}>
            <SideBar></SideBar>
            <div className='container-fluid overflow-x-none'>
                <div className='row'>
                    <div className="container" style={{ minHeight: "100vh", color: "#fff" }}>
                        <div className="row pt-5">
                            <div className="container-fluid text-white">
                                <div className="container-fluid p-5 text-white mb-4 pb-0" style={{ overflowY: 'auto', height: '81vh' }}>
                                    <div className="row mb-4">
                                        <div className="col-md-8">
                                            <TextField
                                                fullWidth
                                                label={<span>Title <span style={{ color: 'red', verticalAlign: 'middle', fontSize: '1.8em' }}>*</span></span>}
                                                variant="filled"
                                                value={title}
                                                placeholder="Enter title"
                                                onChange={(e) => setTitle(e.target.value)}  // Ensure this is defined
                                                InputProps={{ style: { color: 'white' } }}
                                                InputLabelProps={{ style: { color: 'white' } }}
                                                sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}
                                            />

                                        </div>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <div className="col-md-2">
                                                <DateTimePicker
                                                    label={<span>Start Date and Time<span style={{ color: 'red', verticalAlign: 'middle', fontSize: '1.8em' }}>*</span></span>}
                                                    variant="filled"
                                                    value={startDate}
                                                    onChange={handleStartDateChange}
                                                    sx={{
                                                        "& .css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input": { color: 'white' }, // Label color on focus
                                                        "& .MuiSvgIcon-root": { color: 'white' },
                                                        "& .MuiFormLabel-root": { color: 'white' },
                                                        "& .MuiInputBase-input": { color: 'white' },
                                                        bgcolor: 'rgba(255, 255, 255, 0.1)',
                                                        borderRadius: 1,
                                                        width: '100%',
                                                    }}
                                                />
                                            </div>

                                            <div className="col-md-2">
                                                <DateTimePicker
                                                    label={<span>End Date and Time<span style={{ color: 'red', verticalAlign: 'middle', fontSize: '1.8em' }}>*</span></span>}
                                                    variant="filled"
                                                    value={endDate}
                                                    onChange={handleEndDateChange}  // Ensure this is defined
                                                    sx={{
                                                        "& .css-1dune0f-MuiInputBase-input-MuiOutlinedInput-input": { color: 'white' }, // Label color on focus
                                                        "& .MuiSvgIcon-root": { color: 'white' },
                                                        "& .MuiFormLabel-root ": { color: 'white' },
                                                        "& .MuiInputBase-input": { color: 'white' },
                                                        bgcolor: 'rgba(255, 255, 255, 0.1)',
                                                        borderRadius: 1,
                                                        width: '100%',
                                                    }}
                                                    renderInput={() => (
                                                        <TextField
                                                            variant="filled" />
                                                    )}
                                                />
                                            </div>
                                        </LocalizationProvider>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <TextField
                                                fullWidth
                                                label="Guest Email"
                                                variant="filled"
                                                placeholder="Enter guest emails.."
                                                value={emailInput}
                                                onChange={(e) => setEmailInput(e.target.value)}
                                                InputProps={{
                                                    startAdornment: (
                                                        <InputAdornment
                                                            position="start"
                                                            style={{ display: 'flex', overflowX: 'auto', whiteSpace: 'nowrap', maxWidth: '100%' }}
                                                        >
                                                            {guestEmails?.map((email, index) => (
                                                                <Chip
                                                                    key={index}
                                                                    label={email}
                                                                    onDelete={() => handleDeleteGuestEmail(email)}
                                                                    style={{ marginRight: 4, background: '#818181', color: 'white' }}
                                                                />
                                                            ))}
                                                        </InputAdornment>
                                                    ),
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton onClick={handleAddGuestEmail} color="primary">
                                                                <AddIcon />
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                    style: { color: 'white', padding: '1%' },
                                                }}
                                                InputLabelProps={{ style: { color: 'white' } }}
                                                sx={{
                                                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                                                    borderRadius: 1,
                                                    paddingBottom: '8px' // Adjust as needed for multiline spacing
                                                }}
                                            />


                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <TextField
                                                fullWidth
                                                label="Agenda"
                                                variant="filled"
                                                multiline
                                                value={agenda}
                                                placeholder="Enter agenda details here"
                                                onChange={(e) => setAgenda(e.target.value)}  // Make sure onChange is defined
                                                InputProps={{
                                                    style: { color: 'white' },
                                                    inputComponent: TextareaAutosize,
                                                    inputProps: { minRows: 3 },
                                                }}
                                                InputLabelProps={{ style: { color: 'white' } }}
                                                sx={{
                                                    bgcolor: 'rgba(255, 255, 255, 0.1)',
                                                    borderRadius: 1
                                                }}
                                            />

                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <TextField
                                                fullWidth
                                                label="Guest Info"
                                                variant="filled"
                                                placeholder="Enter guest details here"
                                                multiline
                                                value={guestInfo}
                                                onChange={(e) => setGuestInfo(e.target.value)}  // Ensure this is defined
                                                InputProps={{
                                                    style: { color: 'white' },
                                                    inputComponent: TextareaAutosize,
                                                    inputProps: { minRows: 2 }, // Adjust the minimum number of rows as needed
                                                }}
                                                InputLabelProps={{ style: { color: 'white' } }}
                                                sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}
                                            />
                                        </div>
                                    </div>
                                    <div className="row mb-4">
                                        <div className="col">
                                            <TextField
                                                fullWidth
                                                label="Important Links"
                                                variant="filled"
                                                placeholder="Enter any important links here"
                                                multiline
                                                value={importantLinks}
                                                onChange={(e) => setImportantLinks(e.target.value)}
                                                InputProps={{
                                                    style: { color: 'white' },
                                                    inputComponent: TextareaAutosize,
                                                    inputProps: { minRows: 2 }, // Adjust the minimum number of rows as needed
                                                }}
                                                InputLabelProps={{ style: { color: 'white' } }}
                                                sx={{ bgcolor: 'rgba(255, 255, 255, 0.1)', borderRadius: 1 }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="row mb-4">
                                    <div className="col-md-12 d-flex justify-content-end px-4r">
                                        {podcast && (
                                            <Button
                                                variant="contained"
                                                className='mr-2'
                                                sx={{ bgcolor: 'linear-gradient(to right, #472DDB, #0985FB, #00D7FD)', color: 'white' }}
                                                onClick={handleJoinStudio}
                                            >
                                                <span className='text-trans-none'>Join Studio</span>
                                            </Button>
                                        )}
                                        <Button
                                            variant="contained"
                                            // color="secondary"
                                            onClick={handleSave}
                                            sx={{ bgcolor: 'linear-gradient(to right, #472DDB, #0985FB, #00D7FD)', color: 'white' }}
                                        >
                                            <span className='text-trans-none'>Save and Send</span>

                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ErrorModal open={popup.open} onClose={handlePopupClose} title={popup.title} message={popup.message} />
        </div>
    );
}

export default Schedule;